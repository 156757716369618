import { Device } from "@/views/provisioning/devices/repo";
import axios from "axios";
import moment from "moment";
import URLS from "../urls";
import { response } from "express";
interface Devices {
  total_number_of_records: number;
  devices: Device[];
  total_pages: number;
  success: boolean;
}
interface Notifications {
  total_number_of_records: number;
  notificaitons: Device[];
  total_pages: number;
  success: boolean;
}
// Trackers
export const getTrackersListing = async (payload) => {
  const url = URLS.GET_TRACKERS_LISTING;
  return await axios
    .post<Devices>(url, payload)
    .then((response) => response.data);
};
export const deleteTrackerFromPlatform = async (devices) => {
  const url = URLS.DELETE_TRACKER_FROM_PLATFORM + devices;
  return await axios.delete(url).then((response) => response.data);
};

export const updateTracker = async (id, payload) => {
  const url = URLS.UPDATE_TRACKER + id;
  return await axios.put(url, payload).then((res) => res);
};

// Routing Configuration
export const getRoutingListing = async (payload) => {
  const url = URLS.GET_ROUTING_LISTING;
  return await axios.post(url, payload).then((resp) => resp.data);
};

export const routingConfig = async (payload) => {
  const url = URLS.ROUTING_CONFIGURATION;
  return await axios.post(url, payload).then((response) => response.data);
};

export const deleteConfiguration = async (id) => {
  const url = URLS.DELETE_CONFIGURATION + id;
  return await axios.delete(url).then((response) => response);
};

export const updateConfiguration = async (id, payload) => {
  const url = URLS.UPDATE_CONFIGURATION + id;
  return await axios.put(url, payload).then((res) => res);
};

export const binsConfiguration = async (payload) => {
  const url = URLS.BINS_CONFIGURATION;
  return await axios.put(url, payload).then((response) => response.data);
};
// End Routing Configuration

export const getDevicesListing = async (payload) => {
  const url = URLS.GET_DEVICES_LISTING;
  return await axios
    .post<Devices>(url, payload)
    .then((response) => response.data);
};

export const getDevicesExport = async (payload) => {
  const url = URLS.EXPORT_DEVICES;
  return await axios.post(url, payload).then((resp) => resp.data);
};

export const getCustomersListing = async (payload) => {
  const url = URLS.GET_CUSTOMERS_LISTING;
  return await axios.post(url, payload).then((resp) => resp.data);
};

export const getCustomersList = async () => {
  const url = URLS.GET_CUSTOMERS_LIST;
  return await axios.get(url).then((response) => response.data.data);
};

export const checkPassword = async (payload) => {
  const url = URLS.CHECK_USER_PASSWORD;
  return await axios.post(url, payload).then((response) => response);
};
export const updateDeviceData = async (id, payload) => {
  const url = URLS.UPDATE_DEVICE + id;
  return await axios.put(url, payload).then((response) => response);
};
export const deleteDeviceData = async (id) => {
  const url = URLS.DELETE_DEVICE + id;
  return await axios.delete(url).then((response) => response);
};

export const deleteDeviceFromAdmin = async (devices) => {
  const url = URLS.DELETE_DEVICE_FROM_ADMIN;
  return await axios.post(url, devices).then((response) => response);
};

export const deleteDeviceFromPlatform = async (devices) => {
  const url = URLS.DELETE_DEVICE_FROM_PLATFORM + devices;
  return await axios.delete(url).then((response) => response.data);
};

export const createDeviceByCsV = async (payload) => {
  const url = URLS.CREATE_DEVICE_BY_CSV;
  return await axios.post(url, payload).then((response) => response.data);
};

export const createDeviceByRepo = async (payload) => {
  const url = URLS.CSV_REPO;
  return await axios.post(url, payload).then((response) => response.data);
};

export const getBinsListing = async (payload) => {
  const url = URLS.GET_BINS_LISTING;
  return await axios
    .post<Devices>(url, payload)
    .then((response) => response.data);
};
export const getBinList = async (payload) => {
  const url = URLS.GET_BIN_LIST;
  return await axios
    .post<Devices>(url, payload)
    .then((response) => response.data);
};

export const getWasteType = async () => {
  const url = URLS.GET_WASTE_TYPE;
  return await axios.get(url).then((res) => res);
};
export const getUplinkList = async (payload) => {
  const url = URLS.GET_UPLINK_LISTING;
  return await axios.post(url, payload).then((response) => response.data);
};

export const addDevices = async (payload) => {
  const url = URLS.ADD_DEVICES_DATA;
  return await axios.post(url, payload).then((response) => response.data);
};
export const addRepoToPlatform = async (payload) => {
  const url = URLS.ADD_REPO_TO_PLATFORM;
  return await axios.post(url, payload).then((response) => response.data);
};

export const invoiceListing = async (payload) => {
  const url = URLS.GET_INVOICEING_LISTING;
  return await axios.post(url, payload).then((response) => response.data);
};

export const getUsersList = async (payload) => {
  const url = URLS.GET_USERS_LIST;
  return await axios.post(url, payload).then((response) => response.data);
};

export const addNewUsers = async (payload) => {
  const url = URLS.ADD_USERS;
  return await axios.post(url, payload).then((response) => response.data);
};

export const updateUser = async (id, payload) => {
  const url = URLS.UPDATE_USER + id;
  return await axios.put(url, payload).then((res) => res);
};
export const ChangeUserPassword = async (json) => {
  const url = URLS.CHANGE_USER_PASSWORD;
  return await axios.post(url, json).then((res) => res);
};
export const deleteUser = async (id) => {
  const url = URLS.DELETE_USER + id;
  return await axios.delete(url).then((response) => response);
};
export const addNewCompany = async (payload) => {
  const url = URLS.ADD_COMPANIES;
  return await axios.post(url, payload).then((response) => response.data);
};
export const getCompany = async (id) => {
  const url = URLS.GET_COMPANY + id;
  return await axios.get(url).then((response) => {
    console.log("API Response", response.data);
    return response.data;
  });
};
export const deleteCompany = async (id) => {
  const url = URLS.DELETE_COMPANIES + id;
  return await axios.delete(url).then((response) => response);
};

export const updateCompany = async (id, payload) => {
  const url = URLS.UPDATE_COMPANY + id;
  return await axios.put(url, payload).then((res) => res);
};

export const getProductionUrl = async () => {
  const url = URLS.PRODUCTION_SCRIPT;
  return await axios.get(url).then((res) => res);
};
export const credentialSheetCsv = async () => {
  const url = URLS.CREDENTIAL_SHEETS_CSV_NAMES;
  return await axios.get(url).then((res) => res);
};
export const createInvoice = async (payload) => {
  const load = {
    se_account_id: payload.se_account_id ? parseInt(payload.se_account_id) : "",
    currency: payload.currency,
    // bank_number varchar(255),
    po_number: payload.po_number,
    invoice_date: moment(
      payload.invoice_date,
      "MM-DD-YYYY HH:mm:ss",
      true
    ).format("YYYY-MM-DD HH:mm:ss"),
    due_date: moment(payload.due_date, "MM-DD-YYYY HH:mm:ss", true).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    delivery_date: moment(
      payload.delivery_date,
      "MM-DD-YYYY HH:mm:ss",
      true
    ).format("YYYY-MM-DD HH:mm:ss"),
    json: JSON.stringify({
      shipmentList: payload.shipmentList,
      platformList: payload.platformList,
      sensorList: payload.sensorList,
    }),
    content: payload.content,
    price: payload.price,
    po_placement: payload.price,
    payable_at_delivery: payload.price,
    ammount_received: payload.ammount_received,
    ammount_payable: payload.ammount_payable,
    ammount_remaining: payload.ammount_remaining,
    // structured_communication varchar(255),
  };
  const url = URLS.CREATE_INVOICE;
  return await axios.post(url, load).then((res) => {
    res.data;
  });
};

export const updateInvoice = async (payload) => {
  const load = {
    se_account_id: payload.se_account_id ? parseInt(payload.se_account_id) : "",
    currency: payload.currency,
    // bank_number varchar(255),
    po_number: payload.po_number,
    invoice_date: moment(
      payload.invoice_date,
      "MM-DD-YYYY HH:mm:ss",
      true
    ).format("YYYY-MM-DD HH:mm:ss"),
    due_date: moment(payload.due_date, "MM-DD-YYYY HH:mm:ss", true).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    delivery_date: moment(
      payload.delivery_date,
      "MM-DD-YYYY HH:mm:ss",
      true
    ).format("YYYY-MM-DD HH:mm:ss"),
    json: JSON.stringify({
      shipmentList: payload.shipmentList,
      platformList: payload.platformList,
      sensorList: payload.sensorList,
    }),
    content: payload.content,
    price: payload.price,
    po_placement: payload.price,
    payable_at_delivery: payload.price,
    ammount_received: payload.ammount_received,
    ammount_payable: payload.ammount_payable,
    ammount_remaining: payload.ammount_remaining,
    // structured_communication varchar(255),
  };
  const url = URLS.UPDATE_INVOICE + payload.id;
  return await axios.put(url, load).then((res) => {
    res.data;
  });
};

export const getInvoice = async (id) => {
  const url = URLS.GET_INVOICE + id;
  return await axios.get(url).then((response) => response.data);
};
// Notifications
export const getNotificationListing = async (payload) => {
  const url = URLS.GET_NOTIFICATION_LIST;
  return await axios
    .post<Notifications>(url, payload)
    .then((response) => response.data);
};
export const addNotificationRule = async (payload) => {
  const url = URLS.ADD_NOTIFICATION_RULES;
  return await axios.post(url, payload).then((res) => res.data);
};
export const addNotificationEmail = async (payload) => {
  const url = URLS.ADD_NOTIFICATION_EMAIL;
  return await axios.post(url, payload).then((response) => response.data);
};
export const getEmailNotificationList = async (id) => {
  const url = URLS.GET_NOTIFICATION_EMAIL_LIST + id;
  return await axios.get(url).then((response) => response.data);
};
export const deleteEmail = async (id) => {
  const url = URLS.DELETE_NOTIFICATION_EMAIL + id;
  return await axios.delete(url).then((response) => response.data);
};
export const deleteNotification = async (id) => {
  const url = URLS.DELETE_NOTIFICATION + id;
  return await axios.delete(url).then((response) => response.data);
};
export const getEmailRules = async (id) => {
  const url = URLS.GET_NOTIFICATION_RULES + id;
  return await axios.get(url).then((response) => response.data);
};
export const updateNotification = async (id, payload) => {
  const url = URLS.GET_NOTIFICATION_RULES + id;
  return await axios.put(url, payload).then((response) => response.data);
};
export const addNotificationMobile = async (payload) => {
  const url = URLS.ADD_NOTIFICATION_MOBILE;
  return await axios.post(url, payload).then((response) => response.data);
};
export const getMobileNotificationList = async (id) => {
  const url = URLS.GET_NOTIFICATION_MOBILE_LIST + id;
  return await axios.get(url).then((response) => response.data);
};
export const deleteMobile = async (id) => {
  const url = URLS.DELETE_NOTIFICATION_MOBILE + id;
  return await axios.delete(url).then((response) => response.data);
};

// INVOICES
export const deleteInvoice = async (id) => {
  const url = URLS.DELETE_INVOICE + id;
  return await axios.delete(url).then((response) => response.data);
};

// Roles APi Integrate
export const addNewRole = async (payload) => {
  const url = URLS.ADD_NEW_ROLE;
  return await axios.post(url, payload).then((response) => response.data);
};
export const getUserRole = async () => {
  const url = URLS.USER_ROLE;
  return await axios.get(url).then((response) => response.data);
};
export const deleteRole = async (id) => {
  const url = URLS.DELETE_ROLE + id;
  return await axios.delete(url).then((response) => response.data);
};
// Services
export const getServicesList = async () => {
  const url = URLS.SERVICES_LIST;
  return await axios.get(url).then((response) => response.data);
};

//Features
export const getFeatureList = async () => {
  const url = URLS.FEATURE_LIST;
  return await axios.get(url).then((response) => response.data);
};

export const addFeature = async (payload) => {
  const url = URLS.NEW_ADD_FEATURE;
  return await axios.post(url, payload).then((response) => response.data);
};
export const updateFeature = async (id, payload) => {
  const url = URLS.UPDATE_FEATURE + id;
  return await axios.put(url, payload).then((response) => response.data);
};
export const deleteFeature = async (id) => {
  const url = URLS.UPDATE_FEATURE + id;
  return await axios.delete(url).then((response) => response.data);
};
//Permission
export const addNewPermission = async (payload) => {
  const url = URLS.NEW_PERMISSION;
  return await axios.post(url, payload).then((response) => response.data);
};

export const permissionList = async () => {
  const url = URLS.PERMISSION_LIST;
  return await axios.get(url).then((response) => response.data);
};

export const updatePermission = async (id, payload) => {
  const url = URLS.UPDATE_PERMISSION + id;
  return await axios.put(url, payload).then((response) => response.data);
};

export const deletePermission = async (id) => {
  const url = URLS.DELETE_PERMISSION + id;
  return await axios.delete(url).then((response) => response.data);
};
export const assignPermission = async (payload) => {
  const url = URLS.ASSIGN_PERMISSION;
  return await axios.post(url, payload).then((response) => response.data);
};

export const unassignPermission = async (payload) => {
  const url = URLS.UN_ASSIGN_PERMISSION;
  return await axios.post(url, payload).then((response) => response.data);
};
export const permission_By_Role = async (payload) => {
  const url = URLS.PERMISSION_BY_ROLE;
  return await axios.post(url, payload).then((response) => response.data);
};
export const managePermission = async (payload) => {
  const url = URLS.MANAGE_PERMISSION;
  return await axios.put(url, payload).then((response) => response.data);
};
export const editRole = async (id, payload) => {
  const url = URLS.EDIT_ROLE + id;
  return await axios.put(url, payload).then((response) => response.data);
};
//Assign waste type

export const wasteType_list = async () => {
  const url = URLS.WASTE_TYPE_LIST;
  return await axios.get(url).then((response) => response.data);
};

export const addwasteType = async (payload) => {
  const url = URLS.ADD_WASTE_TYPE;
  return await axios.post(url, payload).then((response) => response.data);
};

export const updatewasteType = async (id, payload) => {
  const url = URLS.UPDATE_WASTE_TYPE + id;
  return await axios.put(url, payload).then((response) => response.data);
};
export const deletewasteType = async (id) => {
  const url = URLS.DELETE_WASTE_TYPE + id;
  return await axios.delete(url).then((response) => response.data);
};
export const assignwasteType = async (payload) => {
  const url = URLS.ASSIGN_WASTE_TYPES;
  return await axios.post(url, payload).then((response) => response.data);
};
export const wasteTypeByCompany = async (payload) => {
  const url = URLS.WASTE_TYPE_BY_COMPANY;
  return await axios.post(url, payload).then((response) => response.data);
};
//CSV_DECODER
export const uploadCsvDecoder = async (payload) => {
  const url = URLS.CSV_DECODER;
  return await axios.post(url, payload).then((response) => response.data);
};
//Depth map and Mobility
export const enable_depth_Map = async (payload) => {
  const url = URLS.DEPTH_MAP;
  return await axios.put(url, payload).then((response) => response.data);
};
export const enable_mobility = async (payload) => {
  const url = URLS.BINS_MOBILITY;
  return await axios.put(url, payload).then((response) => response.data);
};
// Notification
export const enableNotification = async (payload) => {
  const url = URLS.Bin_Notification;
  return await axios.put(url, payload).then((response) => response.data);
};
//Bins operation data update
export const update_bin_dimension = async (payload) => {
  const url = URLS.UPDATE_BIN_DIMENSION;
  return await axios.put(url, payload).then((response) => response.data);
};
export const update_bin_waste_type = async (payload) => {
  const url = URLS.UPDATE_BIN_WASTE_TYPE_ID;
  return await axios.put(url, payload).then((response) => response.data);
};

export const update_bin_location = async (payload) => {
  const url = URLS.UPDATE_BIN_LOCATION;
  return await axios.put(url, payload).then((response) => response.data);
};

export const update_bin_offset = async (payload) => {
  const url = URLS.OFFSET;
  return await axios.put(url, payload).then((response) => response.data);
};

export const update_bin_full_at = async (payload) => {
  const url = URLS.UPDATE_FULL_AT;
  return await axios.put(url, payload).then((response) => response.data);
};

export const updateThreshold = async (payload) => {
  const url = URLS.UPDATE_THRESHOLD + payload.id;
  delete payload.id;
  payload.threshold = parseInt(payload.threshold);
  return await axios.put(url, payload).then((response) => response.data);
};
//Analysis Report
export const getDevicesData = async (payload) => {
  const url = URLS.DEVICE_DATA;
  return await axios.post(url, payload).then((response) => response.data);
};
export const getCompaniesData = async (payload) => {
  const url = URLS.COMPANIES_DATA;
  return await axios.post(url, payload).then((response) => response.data);
};

// KPI APIs
export const getGroupList = async (payload) => {
  const url = URLS.GROUP_LIST;
  return await axios.post(url, payload).then((response) => response.data)
}
export const AddAddressGroup = async (payload) => {
  const url = URLS.ADD_ADDRESS_GROUP;
  return await axios.post(url, payload).then((response) => response.data)
}
export const getAddressList = async (payload) => {
  const url = URLS.ADDRESS_LIST;
  return await axios.post(url, payload).then((response) => response.data)
}
export const AddAddress = async (payload) => {
  const url = URLS.ADD_ADDRESS;
  return await axios.post(url, payload).then((response) => response.data)
}
export const AddressDetails = async (id) => {
  const url = URLS.ADDRESS_DETAILS + id;
  return await axios.get(url).then((response) => response.data)
}